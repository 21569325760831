
        import * as module0 from './controllers/alternatives_controller.js';import * as module1 from './controllers/filter_controller.js';import * as module2 from './controllers/float_controller.js';import * as module3 from './controllers/popup_controller.js';import * as module4 from './controllers/search_controller.js';import * as module5 from './controllers/share_controller.js';import * as module6 from './controllers/sticky_title_controller.js'
        const modules = {
            "./controllers/alternatives_controller.js": module0,
            "./controllers/filter_controller.js": module1,
            "./controllers/float_controller.js": module2,
            "./controllers/popup_controller.js": module3,
            "./controllers/search_controller.js": module4,
            "./controllers/share_controller.js": module5,
            "./controllers/sticky_title_controller.js": module6,
        };
        export default modules;
      